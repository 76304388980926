import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";

// import { StaticImage } from "gatsby-plugin-image";
//import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  //description,
  row,
  //col6,
  col12,
  // marginTopSubstitute,
  //hubSpotForm,
} from "../subpage.module.scss";

const BestaetigungPage = () => {
  return (
    <Layout pageTitle="Bestätigung" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container neutralPageWidth={true}>
            <div className={row}>
              <div className={col12}>
                <h1>
                  Besten Dank für die Bestätigung Ihrer E-Mail-Adresse. Wir
                  werden Sie nachhaltig über Neuigkeiten zu unseren Produkten
                  und Events auf dem Laufenden halten.
                </h1>
                <p className="text-medium">
                  Natürlich können Sie die Benachrichtigungen jederzeit
                  abbestellen.
                </p>
                <p>
                  Weitere Informationen zum Abbestellen, zu unseren
                  Datenschutzverfahren und dazu, wie wir Ihre Privatsphäre
                  schützen und respektieren, finden Sie in unserer
                  Datenschutzrichtlinie.
                </p>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default BestaetigungPage;
